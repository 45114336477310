<template>
    <div class="main">
        <div class="search">
            <div class="refresh" @click="getLeisureGame"><i class="fa fa-refresh"></i></div>
            <!--미니게임 선택-->
            <leisuregame-selector-comp :pkind="this.leisureGame.kind"
                                       @changeLeisureKind="changeLeisureKind"></leisuregame-selector-comp>


            <el-select size="mini" style="width: 100px" v-model="selectedStatus" placeholder="상태선택"
                       @change="changeStatus">
                <el-option value="wait" label="베팅대기"></el-option>
                <el-option value="open" label="베팅가능"></el-option>
                <el-option value="start" label="베팅마감"></el-option>
                <el-option value="end" label="종료"></el-option>
            </el-select>

            <el-popover v-if="leisureGame.status !== managerConst.Game.STATUS_END"
                        placement="bottom"
                        popper-class="bg-title"
                        width="270" style="margin-left: 20px">
                <div>
                    <el-button size="mini" type="warning"
                               @click="updateGameStatus(managerConst.Game.STATUS_OPEN)">[가능]상태 로 변경
                    </el-button>
                    <el-button size="mini" type="warning"
                               @click="updateGameStatus(managerConst.Game.STATUS_STARTING)">[마감]상태 로 변경
                    </el-button>
                </div>
                <el-button size="mini" type="warning" slot="reference">상태처리</el-button>
            </el-popover>

            <el-popover v-if="leisureGame.status !== managerConst.Game.STATUS_END"
                        placement="bottom"
                        popper-class="bg-title"
                        width="380" style="margin-left: 5px">
                <div class="btns">
                    <el-button size="mini" type="warning"
                               @click="cancelGame(managerConst.LeisureGame.LEISURE_RESULT_CACEL)">선택경기 [취소]처리
                    </el-button>
                    <el-button size="mini" type="warning"
                               @click="cancelGame(managerConst.LeisureGame.LEISURE_RESULT_SPECIALCASE)">선택경기
                        [적특]처리
                    </el-button>
                    <el-button size="mini" type="success" @click="liquidation">베팅정산</el-button>
                </div>
                <el-button size="mini" type="info" slot="reference">결과처리</el-button>
            </el-popover>
        </div>

        <div class="data">
            <el-table
                    :data="leisureGameList"
                    style="width: 100%"
                    max-height="750"
                    border
                    @selection-change="handleSelectionChange">
                <!--더보기-->
                <el-table-column type="expand" label="더보기" width="60">
                    <template slot-scope="props">
                        <el-form label-position="left" class="demo-table-expand">
                            <el-form-item label="정보">
                                <span>({{ props.row.id }})</span>
                                <span>{{ props.row.kindConfig.kindName}}</span> - <span>{{props.row.sequence}} 회차</span>
                            </el-form-item>
                            <el-form-item>
                                <el-button v-if="props.row.status === managerConst.Game.STATUS_END"
                                           @click.native.prevent="initLeisureGame(props.row)"
                                           type="danger"
                                           size="small">
                                    초기화
                                </el-button>
                            </el-form-item>

                            <el-form-item>
                                <el-button v-if="props.row.status !== managerConst.Game.STATUS_END"
                                           @click.native.prevent="insertResult(props.row)"
                                           type="primary"
                                           size="small">
                                    결과입력
                                </el-button>
                            </el-form-item>

                        </el-form>
                    </template>
                </el-table-column>

                <!--상태-->
                <el-table-column
                        fixed
                        label="상태"
                        width="45">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status === managerConst.Game.STATUS_CHECKING">대기</span>
                        <span v-if="scope.row.status === managerConst.Game.STATUS_OPEN">가능</span>
                        <span v-if="scope.row.status === managerConst.Game.STATUS_STARTING">마감</span>
                        <span v-if="scope.row.status === managerConst.Game.STATUS_END">종료</span>
                    </template>
                </el-table-column>
                <!--체크박스-->
                <el-table-column
                        fixed
                        type="selection"
                        width="50">
                </el-table-column>
                <!--종목-->
                <el-table-column
                        fixed
                        label="종목"
                        width="150">
                    <template slot-scope="scope">
                        <span>{{scope.row.kindConfig.kindName}}</span>
                    </template>
                </el-table-column>
                <!--번호-->
                <el-table-column
                        fixed
                        label="번호"
                        width="80">
                    <template slot-scope="scope">
                        <span>{{scope.row.id}}</span>
                    </template>
                </el-table-column>
                <!--DateRound-->
                <el-table-column
                        fixed
                        label="날짜"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.startDayStr}}</span>
                    </template>
                </el-table-column>
                <!--DateRound-->
                <el-table-column
                        fixed
                        label="시작시간"
                        width="110">
                    <template slot-scope="scope">
                        <span :class="{'bg-warning' : scope.row.autoclosed === managerConst.NOT}">{{scope.row.startTime|datef('DD일 HH:mm:ss')}}</span>
                    </template>
                </el-table-column>
                <!--일회차-->
                <el-table-column
                        fixed
                        label="일회차"
                        width="50">
                    <template slot-scope="scope">
                        <span>{{scope.row.sequence}}</span>
                    </template>
                </el-table-column>
                <!--베팅수-->
                <el-table-column
                        fixed
                        label="베팅수"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.betQuantity|comma}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅금"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.betCashAmount|comma}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="타입"
                        width="50">
                    <template slot-scope="scope">
                        <span v-if="scope.row.sportsKind == managerConst.Game.GAME_KIND_1X2">1x2</span>
                        <span style="color: red"
                              v-if="scope.row.sportsKind == managerConst.Game.GAME_KIND_HANDICAP">핸</span>
                        <span style="color: blue"
                              v-if="scope.row.sportsKind == managerConst.Game.GAME_KIND_OVERUNDER">오언</span>
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="팀정보"
                        width="230">
                    <template slot-scope="scope">
                              <span v-if="scope.row.sportsKind == managerConst.Game.GAME_KIND_1X2">
                                                         <span>{{'홈승:'+scope.row.homeTeamName+'('+scope.row.homeTeamOdds+')'}}</span><br>
                                                         <span>{{'무'+'  ('+scope.row.drawOdds+')'}}</span><br>
                                                         <span>{{'원승:'+scope.row.awayTeamName+'('+scope.row.awayTeamOdds+')'}}</span>
                                                     </span>
                        <span v-if="scope.row.sportsKind == managerConst.Game.GAME_KIND_OVERUNDER"
                              class="text-primary">
                                                        <span>{{'오버:'+scope.row.homeTeamName+'('+scope.row.homeTeamOdds+')'}}</span><br>
                                                         <span>{{'기준'+'  ('+scope.row.overunderVal+')'}}</span><br>
                                                         <span>{{'언더:'+scope.row.awayTeamName+'('+scope.row.awayTeamOdds+')'}}</span>
                                                    </span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="결과"
                        width="250">
                    <template slot-scope="scope">
                        <div style="color: #ff0000"
                             v-if="leisureGame.status == managerConst.Game.STATUS_END && (scope.row.leisureGameResult == 'cancel')">
                            취소
                        </div>
                        <div style="color: #ff0000"
                             v-if="leisureGame.status == managerConst.Game.STATUS_END && (scope.row.leisureGameResult == 'specialcase')">
                            적특
                        </div>
                        <div v-if="leisureGame.status == managerConst.Game.STATUS_END &&scope.row.leisureGameResult != 'cancel' && scope.row.leisureGameResult != 'specialcase'">
                            <leisure-game-result-text-comp :pgame="scope.row"></leisure-game-result-text-comp>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {Loading} from "element-ui";
    import LeisuregameSelectorComp from "../../components/administrator/LeisuregameSelectorComp";
    import managerConst from "../../common/administrator/managerConst";
    import {
        getLeisureGameEndList,
        getLeisureGameList, initLeisureGame, liquidationLeisureGame, updateLeisureGameResultCancel,
        updateLeisureGameStatus
    } from "../../network/manager/leisureRequest";
    import LeisureGameResultTextComp from "../../components/administrator/LeisureGameResultTextComp";
    import {initGame, updateGameResultCancel} from "../../network/manager/sportsRequest";
    import LeisureGameInsertResultComp from "../../components/administrator/LeisureGameInsertResultComp";
    import Vue from "vue";
    import LeisureResultToast from "../../components/administrator/LeisurePanel/LeisureResultToast";

    Vue.prototype.$leisureResultToast = LeisureResultToast

    export default {
        name: "ManagerLeisureGame",
        components: {
            LeisureGameInsertResultComp,
            LeisureGameResultTextComp,
            LeisuregameSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp
        },
        data() {
            return {
                pageNum: 1,
                pageSize: 50,
                orderBy: 'start_time ASC',
                pageTotal: 0,
                multipleSelection: [],
                leisureGame: {'status': managerConst.Game.STATUS_OPEN, 'kind': 0, ids: []},
                selectedStatus: 'open',
                fullscreenLoading: false,
                leisureGameList: [],
                managerConst,
                insertResultGame: {'miniGameResult': {}, 'kindConfig': {}},
                insertResultDialogVisible: false,

            }
        },
        methods: {
            getLeisureGame() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if (this.leisureGame.status === managerConst.Game.STATUS_END) {
                    this.orderBy = 'start_time DESC'
                    getLeisureGameEndList(this.leisureGame.kind, this.pageNum, this.pageSize, this.orderBy).then(res => {
                        this.pageTotal = res.data.total;
                        this.leisureGameList = res.data.data
                        loadingInstance.close();
                        this.leisureGameList.map(g => {
                            if (g.leisureGameResult.indexOf("{") !== -1) {
                                g.leisureGameResult = JSON.parse(g.leisureGameResult);
                            }
                        })
                    })
                } else {
                    this.orderBy = 'start_time ASC'
                    getLeisureGameList(this.leisureGame, this.pageNum, this.pageSize, this.orderBy).then(res => {
                        this.pageTotal = res.data.total;
                        this.leisureGameList = res.data.data
                        loadingInstance.close();
                    })
                }

            },
            changeStatus() {
                switch (this.selectedStatus) {
                    case "wait":
                        this.leisureGame.status = managerConst.Game.STATUS_CHECKING
                        break
                    case "open":
                        this.leisureGame.status = managerConst.Game.STATUS_OPEN
                        break
                    case "start":
                        this.leisureGame.status = managerConst.Game.STATUS_STARTING
                        break
                    case "end":
                        this.leisureGame.status = managerConst.Game.STATUS_END
                        break
                }
                this.leisureGameList = []
                this.pageNum = 1
                this.getLeisureGame()
            },
            changeLeisureKind(kind) {
                this.leisureGame.kind = kind
                this.getLeisureGame()
            },
            updateGameStatus(status) {
                if (this.leisureGame.ids.length === 0) {
                    this.$message({
                        showClose: false,
                        duration: 1500,
                        message: '경기를 선택하세요',
                        type: 'error'
                    });
                    return false;
                }
                let record = {}
                record.ids = this.leisureGame.ids;
                record.status = status;
                updateLeisureGameStatus(record).then(res => {
                    if (res.data.success) {
                        this.getLeisureGame()
                        this.$message({
                            showClose: false,
                            message: '상태변경이 완료되였습니다',
                            duration: 1500,
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 5000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })

            },
            cancelGame(result) {
                if (this.leisureGame.ids.length === 0) {
                    this.$message({
                        showClose: false,
                        duration: 1500,
                        message: '경기를 선택하세요',
                        type: 'error'
                    });
                    return false;
                }

                let cont = '';
                if (result === managerConst.Game.GAME_RESULT_CANCELED) {
                    cont = '취소'
                }
                if (result === managerConst.Game.GAME_RESULT_SPECIAL) {
                    cont = '적특'
                }
                this.$confirm('선택하신 경기를 ' + cont + ' 처리 하시겠습니까?', '취소/적특 처리', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let record = {};
                    record.ids = this.leisureGame.ids;
                    record.leisureGameResult = result;
                    updateLeisureGameResultCancel(record).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '업데이트 완료되였습니다'
                            });
                            this.getLeisureGame()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                        loadingInstance.close()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '업데이트 취소되였습니다'
                    });
                });
            },
            initLeisureGame(game) {

                this.$confirm(game.kindConfig.kindName + ' -  ' + game.sequence + '회차 경기를 초기화 하시겠습니까?', '경기 초기화', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    initLeisureGame(game.id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '초기화 완료되였습니다'
                            });
                            this.getLeisureGame()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '초기화 취소되였습니다'
                    });
                });
            },
            saveManualResultSuccess(){
              this.getLeisureGame()
            },
            liquidation() {
                liquidationLeisureGame()
                this.$message({
                    type: 'success',
                    duration: 1000,
                    message: '정산신청이 완료되였습니다'
                });
            },
            insertResult(game) {
                this.$leisureResultToast({id: game.id})
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getLeisureGame();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getLeisureGame();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.leisureGame.ids = [];
                this.multipleSelection.map(g => {
                    this.leisureGame.ids.push(g.id)
                });
            },
            setPath(){
                this.leisureGame.kind = parseInt(this.$route.query.kind);
            }
        },
        created() {
            this.setPath()
            this.getLeisureGame()
            this.$bus.$on('leisureResultSuccess',()=>{
                console.log('setResultSuccess')
                this.getLeisureGame()
            })
        },
        watch: {
            $route: function (to, from) {
                this.setPath();
                this.getLeisureGame();
            },
        }
    }
</script>

<style scoped>

</style>
<template>
    <el-dialog
            :title="title"
            :visible.sync="centerDialogVisible"
            width="30%"
            modal
            center @close="close">
        <div style="text-align: center;padding: 15px;">
            {{miniGame.kindConfig.kindName}}, {{miniGame.sequence}} 회 ({{miniGame.startTime|datef('HH:mm')}}) ,
            베팅수:{{miniGame.betQuantity|comma}}, 베팅금액:{{miniGame.betCashAmount|comma}}
        </div>

        <!--파워볼 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_POWERBALL
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_3S
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_5S
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NEXT_POWERBALL
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_1M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_2M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_3M">
            <div class="sb">
                <el-input placeholder="볼1" v-model="powerGameResult.ball1">
                    <template slot="prepend">볼1</template>
                </el-input>
                <el-input placeholder="볼2" v-model="powerGameResult.ball2">
                    <template slot="prepend">볼2</template>
                </el-input>
                <el-input placeholder="볼3" v-model="powerGameResult.ball3">
                    <template slot="prepend">볼3</template>
                </el-input>
                <el-input placeholder="볼4" v-model="powerGameResult.ball4">
                    <template slot="prepend">볼4</template>
                </el-input>
                <el-input placeholder="볼5" v-model="powerGameResult.ball5">
                    <template slot="prepend">볼5</template>
                </el-input>
            </div>
            <div class="sb">
                <el-input placeholder="파워볼" v-model="powerGameResult.powerball">
                    <template slot="prepend" class="text-blue">파워볼</template>
                </el-input>
            </div>

        </div>

        <!--가상축구,농구 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER">
            <div>
                T1:{{miniGame.homeTeamName}} vs T2:{{miniGame.awayTeamName}}
                <span v-if="miniGame.sportsKind == managerConst.Game.GAME_KIND_1X2">(승무패)</span>
                <span class="text-blue"
                      v-if="miniGame.sportsKind == managerConst.Game.GAME_KIND_OVERUNDER">(오버언더)</span>
            </div>
            <div class="sb">
                <el-input placeholder="T1 스코어" v-model="miniGameResult.homeTeamScore">
                    <template slot="prepend">T1</template>
                </el-input>
            </div>
            <div class="sb">
                <el-input placeholder="T2 스코어" v-model="miniGameResult.awayTeamScore">
                    <template slot="prepend">T2</template>
                </el-input>
            </div>
        </div>

        <!--개경주,경마 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4">
            <h5>가상 경견,경마 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.first" label="1" border>1</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="2" border>2</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="3" border>3</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="4" border>4</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="5" border>5</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="6" border>6</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="7" border>7</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="8" border>8</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="9" border>9</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="10" border>10</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="11" border>11</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="12" border>12</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="13" border>13</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="14" border>14</el-radio>
                <el-radio size="mini" v-model="miniGameResult.first" label="15" border>15</el-radio>
            </div>
        </div>

        <!--사다리 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SPEEDGAME_PDALI
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_POWERSADALI
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_KINOSADALI
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_1M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_5M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_1M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_3M
                ||miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_3S
                ||miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_5S
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NEXT_SADALI
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_1M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_2M
                || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_3M">
            <h5>사다리 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="odd" border>홀</el-radio>
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="even" border>짝</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.lineCount" label="line3" border>3줄</el-radio>
                <el-radio size="mini" v-model="miniGameResult.lineCount" label="line4" border>4줄</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.leftorright" label="left" border>좌출발</el-radio>
                <el-radio size="mini" v-model="miniGameResult.leftorright" label="right" border>우출발</el-radio>
            </div>
        </div>


        <!--스카이 주사위-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SPEEDKINO">
            <h5>스피드키노 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="odd" border>홀</el-radio>
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="even" border>짝</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.overunder" label="over" border>오버</el-radio>
                <el-radio size="mini" v-model="miniGameResult.overunder" label="under" border>언더</el-radio>
            </div>
        </div>

        <!--게임모아 ACE 홀짝 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_ACE">
            <h5>ACE 홀짝 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="odd" border>홀</el-radio>
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="even" border>짝</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.overunder" label="over" border>오버</el-radio>
                <el-radio size="mini" v-model="miniGameResult.overunder" label="under" border>언더</el-radio>
            </div>
        </div>

        <!--JWCASINO 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_JWCASINO_ANGELDEMONS">
            <h5>JWCASINO 천사악마 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.aordResult" label="a" border>천사</el-radio>
                <el-radio size="mini" v-model="miniGameResult.aordResult" label="d" border>악마</el-radio>
            </div>
        </div>
        <!--JWCASINO 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_JWCASINO_OSTRICHRUN">
            <h5>JWCASINO 타조게임 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.lorrResult" label="l" border>Left</el-radio>
                <el-radio size="mini" v-model="miniGameResult.lorrResult" label="r" border>Right</el-radio>
            </div>
        </div>
        <!--JWCASINO 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_JWCASINO_SUPERMARIO">
            <h5>JWCASINO 슈퍼마리오 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.gorsResult" label="g" border>금</el-radio>
                <el-radio size="mini" v-model="miniGameResult.gorsResult" label="s" border>은</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.sorbResult" label="b" border>대</el-radio>
                <el-radio size="mini" v-model="miniGameResult.sorbResult" label="s" border>소</el-radio>
            </div>
        </div>

        <!--Speed Game 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SPEEDGAME_LM">
            <h5>Speed Game 라이언&무지 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.lmResult" label="l" border>라이언</el-radio>
                <el-radio size="mini" v-model="miniGameResult.lmResult" label="m" border>무지</el-radio>
            </div>
        </div>
        <!--Speed Game 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SPEEDGAME_SPLIT">
            <h5>Speed Game 스피릿 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="odd" border>홀</el-radio>
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="even" border>짝</el-radio>
            </div>
        </div>

        <!--SkyPark Speed 바카라 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA">
            <h5>로투스 바카라 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.skySpeedBakaraWinner" label="1" border>Player</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skySpeedBakaraWinner" label="2" border>Banker</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skySpeedBakaraWinner" label="3" border>TIE</el-radio>
            </div>
        </div>
        <!--Skypark 홀짝 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_MGM_ODDEVEN
             || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN">
            <h5>Skypark 홀짝 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="odd" border>홀</el-radio>
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="even" border>짝</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.skyCardType" label="4" border>♠(스페이드)</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skyCardType" label="1" border>◇(다이아몬드)</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skyCardType" label="3" border>♡(하트)</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skyCardType" label="2" border>♣(클로버)</el-radio>
            </div>
        </div>
        <!--Skypark 주사위 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SKY_DICE">
            <h5>Skypark 주사위 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="odd" border>홀</el-radio>
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="even" border>짝</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.skyoverunder" label="1" border>오버</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skyoverunder" label="2" border>언더</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skyoverunder" label="3" border>TIE</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.skywincolor" label="1" border>RED</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skywincolor" label="2" border>BLUE</el-radio>
                <el-radio size="mini" v-model="miniGameResult.skywincolor" label="3" border>TIE</el-radio>
            </div>
        </div>
        <!--크라운 섯다 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_SUTDA">
            <h5>스카이파크 섯다 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.crownWinner" label="1" border>Korea</el-radio>
                <el-radio size="mini" v-model="miniGameResult.crownWinner" label="2" border>Japan</el-radio>
            </div>
        </div>
        <!--크라운 나인볼 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NINEBALL">
            <h5>스카이파크 나인볼 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.nbWinner" label="1" border>Left</el-radio>
                <el-radio size="mini" v-model="miniGameResult.nbWinner" label="2" border>Right</el-radio>
                <el-radio size="mini" v-model="miniGameResult.nbWinner" label="3" border>TIE</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="odd" border>홀</el-radio>
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="even" border>짝</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.nbr2color" label="blue" border>Blue</el-radio>
                <el-radio size="mini" v-model="miniGameResult.nbr2color" label="red" border>Red</el-radio>
            </div>
        </div>
        <!--로투스 바카라 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1">
            <h5>로투스 바카라 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.lotusBakaraWinner" label="1" border>Player</el-radio>
                <el-radio size="mini" v-model="miniGameResult.lotusBakaraWinner" label="2" border>Banker</el-radio>
            </div>
        </div>
        <!--로투스 용호 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER">
            <h5>로투스 용호 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.winplayer" label="1" border>Dragon</el-radio>
                <el-radio size="mini" v-model="miniGameResult.winplayer" label="2" border>Tiger</el-radio>
            </div>
        </div>
        <!--로투스 홀짝 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS">
            <h5>로투스 홀짝 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="odd" border>홀</el-radio>
                <el-radio size="mini" v-model="miniGameResult.oddeven" label="even" border>짝</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.overunder" label="1" border>오버</el-radio>
                <el-radio size="mini" v-model="miniGameResult.overunder" label="2" border>언더</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.lotusCardColor" label="1" border>Black</el-radio>
                <el-radio size="mini" v-model="miniGameResult.lotusCardColor" label="2" border>Red</el-radio>
            </div>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.lotusCardType" label="0" border>♠(스페이드)</el-radio>
                <el-radio size="mini" v-model="miniGameResult.lotusCardType" label="1" border>◇(다이아몬드)</el-radio>
                <el-radio size="mini" v-model="miniGameResult.lotusCardType" label="2" border>♡(하트)</el-radio>
                <el-radio size="mini" v-model="miniGameResult.lotusCardType" label="3" border>♣(클로버)</el-radio>
            </div>
        </div>
        <!--MGM 바카라 결과입력-->
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_MGM_BAKARA
             || miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NEXT_BACCARA">
            <h5>MGM 바카라 결과입력</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.mgmBakaraWinner" label="1" border>Player</el-radio>
                <el-radio size="mini" v-model="miniGameResult.mgmBakaraWinner" label="2" border>Banker</el-radio>
                <el-radio size="mini" v-model="miniGameResult.mgmBakaraWinner" label="3" border>TIE</el-radio>
            </div>
        </div>

        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_PAMGAME_MARIO">
            <h5>PamGame 마리오 결과</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.pgmarioResult" label="1" border>Mario</el-radio>
                <el-radio size="mini" v-model="miniGameResult.pgmarioResult" label="2" border>Luigiu</el-radio>
            </div>
        </div>
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_PAMGAME_SLOT">
            <h5>PamGame 슬롯 결과</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.pgslotResult" label="1" border>Timon</el-radio>
                <el-radio size="mini" v-model="miniGameResult.pgslotResult" label="2" border>Pumba</el-radio>
            </div>
        </div>
        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_PAMGAME_DRAGONTIGER">
            <h5>PamGame 드래곤 타이거 결과</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.pgdragontigerResult" label="1" border>Dragon</el-radio>
                <el-radio size="mini" v-model="miniGameResult.pgdragontigerResult" label="2" border>Tiger</el-radio>
            </div>
        </div>

        <div class="st"
             v-if="miniGame.kind == managerConst.LeisureGame.LEISURE_KIND_NEXT_RING1M">
            <h5>Next 1분링</h5>
            <div class="sb">
                <el-radio size="mini" v-model="miniGameResult.nextRing1m" label="2" border>2</el-radio>
                <el-radio size="mini" v-model="miniGameResult.nextRing1m" label="3" border>3</el-radio>
                <el-radio size="mini" v-model="miniGameResult.nextRing1m" label="5" border>5</el-radio>
                <el-radio size="mini" v-model="miniGameResult.nextRing1m" label="50" border>50</el-radio>
            </div>
        </div>


        <span slot="footer" class="dialog-footer">
        <el-button @click="close">취소</el-button>
        <el-button type="primary" @click="setScore">확인</el-button>
        </span>
    </el-dialog>

</template>

<script>

    import {getLeisureGameById, saveLeisureGameManualResult} from "../../../network/manager/leisureRequest";
    import {manager} from "../../../common/administrator/managerMixin";

    export default {
        mixins: [manager],
        name: "LeisureResultToast",
        data() {
            return {
                id: null, //
                duration: 0, //
                closed: false,
                centerDialogVisible: false,
                miniGameResult: {},
                powerGameResult: {},
                miniGame: {miniGameResult: {}, kindConfig: {}, powerGameResult: {}},
                title: '',
                homeScore: null,
                awayScore: null,
                sysn: false
            }
        },
        created() {
            this.centerDialogVisible = true;
            getLeisureGameById(this.id).then(res => {
                if (res.data.success) {
                    this.miniGame = res.data.data
                    //this.title = '('+this.game.league.leagueName + ') '+ this.game.homeTeamName + ' : ' + this.game.awayTeamName
                }

            })
        },
        mounted() {

        },
        watch: {
            closed(newVal) {
                if (newVal) {
                    this.miniGame = {miniGameResult: {}, kindConfig: {}, powerGameResult: {}}
                    this.visible = false
                    this.destroyElement()
                }
            }
        },
        methods: {
            close() {
                this.miniGame = {miniGameResult: {}, kindConfig: {}, powerGameResult: {}}
                this.closed = true
                this.centerDialogVisible = false
            },
            setScore() {
                this.miniGame.miniGameResult = this.miniGameResult
                this.miniGame.powerGameResult = this.powerGameResult
                saveLeisureGameManualResult(this.miniGame).then(res => {
                    if (res.data.success) {
                        this.$bus.$emit('leisureResultSuccess', {})

                    } else {
                        this.$bus.$emit('leisureResultSuccess', {})
                    }
                })
                this.close();
            },
            destroyElement() {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
        }
    }
</script>

<style scoped>
    .st {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: column
    }

    .sb {
        border: 1px solid #c6c6c6;
        padding: 5px;
        border-radius: 5px
    }

    .el-radio {
        margin: 5px !important;
    }
</style>